<template>
  <div>
    <SmsSendForm
        v-if="formatedForm"
        @submited="handleForm"
        :placeholders="placeholders"
        :send-variant="sendVariant"
        :cloned-form="formatedForm"
        :allowed-phones="allowed_phones"
        :xls-data="details.xls_data"
    >
    </SmsSendForm>
  </div>
</template>

<script>
import SmsSendForm from "../../components/SmsSendForm";

export default {
  name: "SmsSendDetail",
  components: {SmsSendForm},
  data() {
    return {
      details: null,
      placeholders: [],
      sendVariant: [],
      allowed_phones: []
    }
  },
  methods: {
    async handleForm(form) {
      console.log(form)
      try {


        let payload = {
          'message': form.message_text,
          'name': form.mailing_name,
          'type': form.send_variant,
          'send_phone': form.send_phone,
        }

        if (form.datePeriodType) {
          if (form.datePeriodType === 'dynamic') {
            payload['period'] = {
              type: form.datePeriodType,
              ...form.dynPeriod
            }
          } else if (form.datePeriodType === 'fixed') {
            payload['period'] = {
              type: form.datePeriodType,
              ...form.fixPeriod
            }
          }
        }

        if (form.sendType === 'file' && form.attachment) {
          let temp = new FormData()
          for (const key in payload) {
            temp.append(key, payload[key])
          }
          temp.append('data_file', form.attachment)
          payload = temp;
        }

        console.log(form, payload)

        let {data: res} = await this.$axios.post(`/api/mailing/wa/${this.$route.params.id}`, payload)
        console.log('-----', res);
        if (res.success) {
          return this.$router.push({name: "SmsSend"})
        }
      } catch (e) {
        console.log(e)
      }
    },
    createDropdownData(array, insertData, sameKeys = false) {
      for (const key in insertData) {
        array.push({
          text: insertData[key],
          value: sameKeys ? insertData[key] : key
        })
      }
    },
    async getDetails() {
      try {
        const {data} = await this.$axios.get(`/api/mailing/wa/${this.$route.params.id}/edit`)
        const {placeholders, status_list, allowed_phones} = data;
        for (const key in placeholders) {
          this.placeholders.push(`{{ ${key} }} ${placeholders[key]['title']}`)
        }
        console.log('--------------------', data)
        this.createDropdownData(this.sendVariant, status_list)
        this.createDropdownData(this.allowed_phones, allowed_phones, true)
        // for (const key in status_list) {
        //   this.sendVariant.push({
        //     text: status_list[key],
        //     value: key
        //   })
        // }
        if (data.data) {
          this.details = data.data
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    formatedForm() {
      if (this.details) {
        return {
          message_text: this.details.message,
          mailing_name: this.details.name,
          send_variant: this.details.send_type,
          send_phone: this.details.sender
        }
      }
      return null;
    }
  },
  async created() {
    await this.getDetails();
  }
}
</script>

<style scoped>

</style>